import { Module } from 'vuex';
import api from '@/api';

interface SubscriptionState {
  currency: string;
  groupSubscription: GroupSubscription | null;
  chargebeePlan: ChargebeePlan | null;
}

const CHARGEBEE_PLAN = 'CHARGEBEE_PLAN';
const GROUP_SUBSCRIPTIONS = 'GROUP_SUBSCRIPTIONS';
const SET_CURRENCY = 'SET_CURRENCY';

const subscriptionModule: Module<SubscriptionState, any> = {
  namespaced: true,
  state: {
    currency: 'USD',
    groupSubscription: null,
    chargebeePlan: null,
  },

  getters: {
    isAccountCreateAllowed: (state) =>
      state.groupSubscription?.create_account.is_allowed,
    accountCreateNotAllowedReasons: (state) =>
      state.groupSubscription?.create_account.reasons || [],
    hasMultipleAccounts: (state, getters): undefined | boolean =>
      getters.numberOfAccounts !== undefined
        ? getters.numberOfAccounts > 1
        : undefined,
    numberOfAccounts: (state): number | undefined =>
      state.groupSubscription?.accounts.length,
    nextAccountTier: (state, getters): PlanTier | undefined => {
      const nAccounts: number | undefined = getters.numberOfAccounts;
      if (!state.chargebeePlan || nAccounts === undefined) {
        return undefined;
      }
      return state.chargebeePlan.tiers.find(
        (tier) =>
          tier.starting_unit <= nAccounts + 1 &&
          (!tier.ending_unit || tier.ending_unit >= nAccounts + 1)
      );
    },
  },

  mutations: {
    [SET_CURRENCY](state, currency) {
      state.currency = currency;
    },

    [GROUP_SUBSCRIPTIONS](state, groupSubscription) {
      state.groupSubscription = groupSubscription;
    },

    [CHARGEBEE_PLAN](state, chargebeePlan) {
      state.chargebeePlan = chargebeePlan;
    },
  },

  actions: {
    async getUserCurrency({ commit }) {
      const { currency } = await api.chargebee.userCurrency();
      commit(SET_CURRENCY, currency);
    },

    async getGroupSubscriptions({ commit }) {
      const groupSubscriptions =
        await api.groupSubscriptions.getGroupSubscriptions();
      commit(GROUP_SUBSCRIPTIONS, groupSubscriptions);
    },

    async getChargebeePlan({ commit }) {
      const chargebeePlan = await api.chargebee.getChargebeePlan();
      commit(CHARGEBEE_PLAN, chargebeePlan);
    },
  },
};

export default subscriptionModule;
