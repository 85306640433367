
  /*

  This is a modal dialog that you can stop the user from closing.

  It is teleported to the <body> so that click events bubble directly to the
  body and bypass the DOM elements where this component happens to be mounted.

  It keeps some margin from the screen edges so it still looks like a dialog on
  small screens. It does not grow beyond a set width on very large / wide
  screens to keep it readable.

  The contents of the modal are entirely up to the consumer of this component.

  You would usually not use this component directly, but go for the
  <DefaultDialog /> instead.

  */

  import Vue, { PropType } from 'vue';
  import Teleport from 'vue2-teleport';

  export default Vue.extend({
    components: { Teleport },
    props: {
      /*
        If set to true, cancel events are `preventDefault()`ed. This stops the
        dialog from closing on ESC key for example. This is mostly useful for
        not letting users close a dialog while awaiting the result of an API
        call.
      */
      closable: {
        type: Boolean,
        default: true,
        required: false,
      },
      dialogClass: {
        type: String as PropType<string | undefined>,
        default: undefined,
        required: false,
      },
      narrow: {
        type: Boolean,
        default: false,
        required: false,
      },
      startOpen: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
    data() {
      return { mountDialog: false };
    },
    mounted() {
      if (this.startOpen) {
        this.showModal();
      }
    },
    methods: {
      maybePrevent(event: Event) {
        if (!this.closable) {
          event.preventDefault();
        } else {
          this.$emit('cancel');
        }
      },
      async showModal() {
        this.mountDialog = true;
        // Warning: $nextTick with showModal() in the callback leads to render
        // glitches / the dialog is not displayed in a top modal layer. This is
        // due to interaction with the <Teleport>.
        await this.$nextTick();
        const dialog = this.$refs.dialog as HTMLDialogElement | undefined;
        if (dialog) {
          dialog.showModal();
        } else {
          throw new Error('could not find $refs.dialog');
        }
      },
      close() {
        // Calling this leads to a DOM close event and the below onClose handler
        // firing, which will result in removing the entire <dialog> from the
        // DOM. If this immediately removes the dialog from the DOM, then the
        // native close event is not fired!
        (this.$refs.dialog as HTMLDialogElement | undefined)?.close();
      },
      onClose() {
        // canceling the dialog emits browser cancel and close events in that
        // order
        this.mountDialog = false;
        this.$emit('close');
      },
    },
  });
