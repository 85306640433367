
  import DarkMode from '@/components/DarkMode/DarkMode.vue';
  import Error from '@/components/Error.vue';
  import AdditionalAccountsWelcomeModal from '@/components/GroupSubscription/AdditionalAccountsWelcomeModal.vue';
  import IncompleteDomainNotificationBar from '@/components/IncompleteDomainNotificationBar/IncompleteDomainNotificationBar.vue';
  import SetupGuideCompletedModal from '@/components/QueryModals/SetupGuideCompletedModal.vue';
  import WelcomeComposeModal from '@/components/QueryModals/WelcomeComposeModal.vue';
  import WelcomeModal from '@/components/QueryModals/WelcomeModal.vue';
  import SessionExpiryHandler from '@/components/SessionExpiryHandler/SessionExpiryHandler.vue';
  import SubscriptionNotificationBar from '@/components/SubscriptionNotificationBar/SubscriptionNotificationBar.vue';
  import TotpDisabledWarning from '@/components/TotpDisabledWarning/TotpDisabledWarning.vue';
  import UnsupportedBrowserWarningModal from '@/components/UnsupportedBrowserWarningModal/UnsupportedBrowserWarningModal.vue';
  import RecoveryReminder from '@/components/RecoveryReminder.vue';
  import { initializeBroadcastListener } from '@/lib/broadcast';
  import { DELETED_USER, SUBSCRIPTION_EXPIRED } from '@/router/named-routes';
  import { SET_VERSION } from '@/store/settings/settings-mutation-types';
  import ToastMessageWrapper from '@/wrappers/ToastMessageWrapper/ToastMessageWrapper';

  import Vue from 'vue';
  import { Route } from 'vue-router';
  import { mapActions, mapGetters, mapState } from 'vuex';

  export default Vue.extend({
    name: 'App',
    components: {
      AdditionalAccountsWelcomeModal,
      DarkMode,
      Error,
      IncompleteDomainNotificationBar,
      SessionExpiryHandler,
      SetupGuideCompletedModal,
      SubscriptionNotificationBar,
      ToastMessageWrapper,
      TotpDisabledWarning,
      UnsupportedBrowserWarningModal,
      RecoveryReminder,
      WelcomeComposeModal,
      WelcomeModal,
    },
    data() {
      return {
        titleUnwatcher: () => {},
      };
    },
    computed: {
      ...mapGetters(['sidebarIsOpen', 'isMobileMenu']),
      ...mapGetters('authentication', [
        'hasIncompleteDomains',
        'hasSubscription',
        'isInGrace',
        'isInQuarantine',
        'isLastActiveMonth',
        'isManager',
      ]),
      ...mapState('authentication', [
        'isAuthenticated',
        'unknownError',
        'user',
      ]),
      isBodyScrollingPrevented(): boolean {
        return this.modalIsOpen || (this.sidebarIsOpen && this.isMobileMenu);
      },
      modalIsOpen(): boolean {
        return Boolean(this.$route.query.modal);
      },
      modalComponent(): typeof Vue | null {
        switch (this.$route.query.modal) {
          case 'welcome':
            return WelcomeModal;
          case 'welcome-compose':
            return WelcomeComposeModal;
          case 'setup-guide-completed':
            return SetupGuideCompletedModal;
          case 'update-recovery':
            return RecoveryReminder;
          case 'unsupported-browser-warning':
            return UnsupportedBrowserWarningModal;
          case 'totp-disabled-warning':
            return TotpDisabledWarning;
          case 'welcome-team-account':
            return AdditionalAccountsWelcomeModal;
          default:
            return null;
        }
      },
      showSubscriptionBar(): boolean {
        if (!this.isAuthenticated || !this.isManager) return false;

        /* In quarantine, users see the special SubscriptionExpiredPage instead. */
        if (this.isInQuarantine) return false;

        /* May or may not have Chargebee subscription. Either way, no payment was made. */
        if (this.isInGrace) return true;

        /* No Chargebee subscription yet: start trying to get user to upgrade in last month. */
        if (!this.hasSubscription && this.isLastActiveMonth) {
          return true;
        }

        return false;
      },
      showIncompleteDomainsBar(): boolean {
        return (
          this.isManager &&
          this.hasIncompleteDomains &&
          !this.isInQuarantine &&
          // Check if still in the signup flow to not show the bar in the last step of the signup (questionaire)
          !this.$route.path.includes('signup') &&
          this.$route.name !== DELETED_USER &&
          this.$route.name !== SUBSCRIPTION_EXPIRED
        );
      },
    },
    watch: {
      $route(to: Route) {
        if (to.meta?.title) {
          this.setDocumentTitle(to.meta.title(this));

          if (this.titleUnwatcher) {
            this.titleUnwatcher();
          }

          this.titleUnwatcher = this.$watch(
            () => to.meta?.title(this),
            (newTitle: string) => this.setDocumentTitle(newTitle)
          );
        }
      },
      isBodyScrollingPrevented(newValue: boolean) {
        document.body.classList.toggle('prevent-scroll', newValue);
      },
    },
    created() {
      this.loadLanguage();
      this.loadSidebarStatus();
      this.verifyVersion();
      initializeBroadcastListener(this.$store);
    },
    methods: {
      ...mapActions(['loadLanguage', 'loadSidebarStatus']),
      setDocumentTitle(newTitle: string) {
        let title = 'StartMail';
        if (newTitle) {
          const titleUppercased =
            newTitle.charAt(0).toUpperCase() + newTitle.slice(1);
          const username = this.user.email ? ` - ${this.user.email}` : '';
          title = titleUppercased + username + ' - StartMail';
        }
        document.title = title;
      },
      verifyVersion() {
        if (this.$root.$data.version) {
          this.$store.commit(SET_VERSION, {
            version: this.$root.$data.version,
          });
        }
      },
      async removeModalQuery() {
        const { modal, ...query } = this.$route.query;
        await this.$router.replace({
          ...this.$route,
          name: this.$route.name || undefined,
          query,
        });
      },
    },
  });
