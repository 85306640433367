import { Module } from 'vuex';

const SET_DOMAIN_TOKEN = 'SET_DOMAIN_TOKEN';
const SET_SIGNUP_DOMAIN = 'SET_SIGNUP_DOMAIN';

interface SignupState {
  domain: string;
  domainToken?: string | null;
}

const signupStoreModule: Module<SignupState, any> = {
  namespaced: true,
  state: {
    domain:
      process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
        ? 'local.startmail.org'
        : 'startmail.com',
    domainToken: null,
  },
  actions: {
    setSignupDomain({ commit }, signupDomain: string) {
      commit(SET_SIGNUP_DOMAIN, signupDomain);
    },
    setDomainToken({ commit }, domainToken: string) {
      commit(SET_DOMAIN_TOKEN, domainToken);
    },
  },
  mutations: {
    [SET_SIGNUP_DOMAIN](state, domain: string) {
      state.domain = domain;
    },
    [SET_DOMAIN_TOKEN](state, domainToken: string) {
      state.domainToken = domainToken;
    },
  },
};

export default signupStoreModule;
