
  import Vue from 'vue';
  import DefaultDialog from '@/components/DefaultDialog.vue';
  import { SETTINGS_ACCOUNT } from '@/router/named-routes';
  import SupportCenterLink from './SupportCenterLink.vue';
  export default Vue.extend({
    components: { DefaultDialog, SupportCenterLink },
    data() {
      return { SETTINGS_ACCOUNT };
    },
  });
