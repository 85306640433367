import { logWarning } from '@/mixins/error-logger-mixin';
import store from '@/store';

const debug = process.env.NODE_ENV !== 'production';

// Redirect routing to routes with a feature flag back to root if the feature is not enabled
export default async function featureGuard(to, from, next) {
  try {
    await store.dispatch('features/getFeatures');
  } catch (err) {
    // getting the feature flags failed
    // on production, all features will be disabled
    // on debug, all features will be enabled
  }

  if (to.meta.featureFlag) {
    const feature = store.getters['features/featureById']?.(
      to.meta.featureFlag
    );

    // On debug environments, check if the feature is known
    // If it is known, only look at if its enabled or not
    // If it is not known, enable it by default
    // On other environments where the feature is not known, log it and disable the feature
    if (feature ? feature.enabled : debug) {
      next();
    } else {
      if (!feature && store.getters['features/loaded']?.()) {
        logWarning(
          `Disabling unknown feature ${to.meta.featureFlag}, redirect to root`
        );
      }
      next({ path: '/' });
    }
  } else {
    next();
  }
}
