import axios from 'axios';
import Vue from 'vue';
import Router from 'vue-router';

import routes from '@/router/routes';
import {
  LOGIN,
  SUBSCRIPTION_EXPIRED,
  MAIL,
  COOKIES_NOT_ENABLED,
} from '@/router/named-routes';
import featureGuard from '@/router/feature-guard';
import { UNAUTHENTICATED } from '@/api/error-codes';

Vue.use(Router);

const savedScrollPositions = new Map();

export const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior(to, from) {
    // Do not scroll if the main view does not change
    if (to.name === from.name) {
      return {};
    }

    // Scroll to a previously saved position.
    // Wait for 200ms until the route animation is completed and the entire page is rendered
    if (savedScrollPositions.has(to.fullPath)) {
      return new Promise((resolve) => {
        window.setTimeout(
          () => resolve(savedScrollPositions.get(to.fullPath)),
          200
        );
      });
    }

    // When changing view, scroll to top
    return { x: 0, y: 0 };
  },
});

export default router;

router.beforeEach(ensureCookiesEnabled);
router.beforeEach(enforceRouteAccessLevel);
router.beforeEach(featureGuard);
router.beforeEach(maybeSaveScrollPosition);

/*
Enforce cookie support for the entire application. Without cookies we
cannot process signup and authentication.
*/
function ensureCookiesEnabled(to, from, next) {
  if (navigator.cookieEnabled) {
    return next();
  } else if (to.name === COOKIES_NOT_ENABLED) {
    return next();
  } else {
    return next({ name: COOKIES_NOT_ENABLED, params: { originalToPath: to } });
  }
}

/*
Enforce route access level. All routes can have an access level at
route.meta.access. When a route is accessed that is not allowed for the
current access level, then the router pushes the user to the appropriate
error page.

The default access level is 'authenticated'. The available access levels
are:

- 'manager': only accessible in authenticated sessions of managers
- 'authenticated': only accessible in authenticated sessions
- 'unauthenticated': only accessible in unauthenticated sessions
- 'public': accessible in all sessions
*/
async function enforceRouteAccessLevel(to, from, next) {
  if (!router.app) return next(); // tests only

  let authenticated;
  const access = to.meta.access ?? 'authenticated';
  const store = router.app.$store;

  if (access === 'public') {
    // Important that this is before the auth check, in case something goes
    // wrong with the auth check and the user is redirected to the public
    // error page. If this is after auth check, then this leads to a very
    // tight request loop.
    return next();
  }

  try {
    authenticated =
      store.state.authentication.isAuthenticated ||
      (await store.dispatch('authentication/getAuthenticationStatus'))
        .is_authenticated;
  } catch (err) {
    if (err.response?.status === 401) {
      authenticated = false;
    } else {
      store.dispatch('authentication/setUnknownError', { error: err });
      throw err;
    }
  }

  if (access === 'unauthenticated') {
    // authenticated users cannot access pages for unauthenticated users (like
    // login and signup)
    return !authenticated ? next() : next({ name: MAIL });
  }

  const quarantined =
    authenticated && store.getters['authentication/isInQuarantine'];
  const manager = authenticated && store.getters['authentication/isManager'];

  if (quarantined && to.name !== SUBSCRIPTION_EXPIRED) {
    // redirect to expiry page when quarantined
    if (manager) {
      return next({ name: SUBSCRIPTION_EXPIRED });
    } else {
      // This is needed to prevent a redirect loop for expired non-managers:
      // expiry page -> inbox -> expiry page.
      await store.dispatch('authentication/logout', { location: 'login' });
      throw new Error('should never get back here after logout');
    }
  } else if (!quarantined && to.name === SUBSCRIPTION_EXPIRED) {
    // can't access expiry page when not expired
    return next({ name: MAIL });
  }

  if (access === 'manager') {
    return manager ? next() : next({ name: MAIL });
  }
  if (access === 'authenticated') {
    return authenticated
      ? next()
      : next({ name: LOGIN, params: { originalRoute: to } });
  }
}

function maybeSaveScrollPosition(to, from, next) {
  // Save scroll position for message lists when changing route
  if (from.name === 'FolderView' || from.name === 'SearchMail') {
    savedScrollPositions.set(from.fullPath, {
      x: window.pageXOffset,
      y: window.pageYOffset,
    });
  }

  next();
}

// Vue-router considers navigation via push to the same route to be an error,
// see https://github.com/vuejs/vue-router/issues/2963 and other linked discussions. This behavior is burdensome, since
// it means each use of router.push must deal with deduplicating calls in case a user has double clicked (for example).
// Instead, this change modifies the push to perform the deduplication and return the ordinary promise. It is far more
// likely that we will ignore the returned promise or do something safe then it is that we will need to perform a
// deduplication, and a deduplication will look similar in any case.
const originalPush = router.push;
router.push = async function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }

  try {
    return await originalPush.call(this, location, onResolve, onReject);
  } catch (err) {
    // If there is no error, this means the navigation was successful, but not
    // to the original destination.
    if (!err || err.name === 'NavigationDuplicated') {
      return this.currentRoute;
    }

    throw err;
  }
};

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const currentRoute = router.history.current;
    const store = router.app.$store;
    const sessionIsExpired =
      store.state.authentication.isAuthenticated &&
      error.response?.data?.code === UNAUTHENTICATED;
    if (sessionIsExpired) {
      store.dispatch('authentication/processAuthentication', {
        is_authenticated: false,
      });
      router.push({
        name: LOGIN,
        params:
          currentRoute.path !== '/'
            ? { originalRoute: currentRoute, query: currentRoute.query }
            : undefined,
      });
    }

    return Promise.reject(error);
  }
);
