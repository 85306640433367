
  import Vue from 'vue';
  import QueryModal from '@/components/QueryModals/QueryModal.vue';

  export default Vue.extend({
    name: 'WelcomeModal',
    components: { QueryModal },
    methods: {
      go() {
        (this.$refs.queryModal as any).closeModal();
      },
    },
  });
