
  /*

  This builds on <ModalDialog /> adding styles and slots for a "regular" dialog
  with a header with a close button and contents below. This should usually be
  used when a dialog is needed, instead of directly using <ModalDialog />.

  */

  import Vue, { PropType } from 'vue';
  import Icon from './Icon/Icon.vue';
  import ModalDialog from './ModalDialog.vue';

  export default Vue.extend({
    components: { Icon, ModalDialog },
    props: {
      closable: {
        type: Boolean as PropType<boolean>,
        default: true,
        required: false,
      },
      dialogClass: {
        type: String as PropType<string | undefined>,
        default: undefined,
        required: false,
      },
    },
    methods: {
      showModal() {
        (this.$refs.dialog as any)?.showModal();
      },
      close() {
        (this.$refs.dialog as any)?.close();
      },
    },
  });
